<template>
  <v-form
    @submit.prevent="onSubmit"
    v-show="!!id ? verification.id : true"
    v-model="valid"
    lazy-validation
    class="verificationForm"
    ref="verificationForm"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card class="mb-6">
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  v-model="verification.type_id"
                  :items="types.map(item => ({ value: item.id, text: item.name }))"
                  :disabled="!!id"
                  :rules="[v => !!v || 'Выберите тип']"
                  @change="updatePrice"
                >
                  <template #label>
                    Тип<span class="error--text">*</span>
                  </template>
                </v-select>
              </v-col>

              <v-col>
                <v-select
                  v-model="verification.job_type_id"
                  :items="job_types.map(item => ({ value: item.id, text: item.name }))"
                  :rules="[v => !!v || 'Выберите тип работы']"
                  :disabled="$store.getters.user.role === 'worker'"
                >
                  <template #label>
                    Тип работы<span class="error--text">*</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  v-model="verification.region_id"
                  :items="regions.map(item => ({ value: item.id, text: item.name }))"
                  @change="regionSelected"
                  :rules="[v => !!v || 'Выберите регион']"
                  :disabled="$store.getters.user.role === 'worker'"
                >
                  <template #label>
                    Регион<span class="error--text">*</span>
                  </template>
                </v-select>
              </v-col>

              <v-col>
                <v-select
                  v-model="verification.district_id"
                  :items="filteredDistricts.map(item => ({ value: item.id, text: item.name }))"
                  @change="districtSelected"
                  :rules="[v => !!v || 'Выберите район']"
                  :disabled="$store.getters.user.role === 'worker'"
                >
                  <template #label>
                    Район<span class="error--text">*</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <dadata
                  v-model="verification.address"
                  :region-code="regions.find(r => r.id === verification.region_id) ? regions.find(r => r.id === verification.region_id).code : null"
                  :disabled="!verification.region_id || $store.getters.user.role === 'worker'"
                  @addressSelected="addressSelected"
                />
              </v-col>
            </v-row>

            <div class="addressWrapper">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Город"
                    v-model="verification.city"
                    :disabled="$store.getters.user.role === 'worker'"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    label="Улица"
                    v-model="verification.street"
                    :disabled="$store.getters.user.role === 'worker'"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    label="Дом"
                    v-model="verification.house"
                    :disabled="$store.getters.user.role === 'worker'"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    label="Корпус"
                    v-model="verification.corps"
                    :disabled="$store.getters.user.role === 'worker'"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    label="Квартира"
                    v-model="verification.flat"
                    :disabled="$store.getters.user.role === 'worker'"
                  />
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col>
                <v-select
                  v-model="verification.client_status_id"
                  :items="client_statuses.map(item => ({ value: item.id, text: item.name }))"
                  :rules="[v => !!v || 'Выберите статус']"
                  :disabled="$store.getters.user.role === 'worker'"
                >
                  <template #label>
                    Статус заказчика<span class="error--text">*</span>
                  </template>
                </v-select>
              </v-col>

              <v-col>
                <v-text-field
                  v-model="verification.name"
                  :rules="[v => !!v || 'Введите ФИО']"
                  :disabled="$store.getters.user.role === 'worker'"
                >
                  <template #label>
                    ФИО<span class="error--text">*</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col :class="showPhone1 ? '' : 'col-6'">
                <v-text-field
                  v-model="verification.phone"
                  v-mask="'8 (###) ###-##-##'"
                  :rules="[v => !!v || 'Введите телефон']"
                  :disabled="$store.getters.user.role === 'worker'"
                >
                  <template #label>
                    Телефон<span class="error--text">*</span>
                  </template>
                </v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  v-if="showPhone1"
                  label="Дополнительный телефон"
                  v-model="verification.phone_1"
                  v-mask="'8 (###) ###-##-##'"
                  :disabled="$store.getters.user.role === 'worker'"
                />

                <v-btn
                  v-else
                  class="mt-3"
                  @click="showPhone1 = true"
                  outlined
                  :disabled="$store.getters.user.role === 'worker'"
                >
                  Добавить номер
                </v-btn>
              </v-col>

              <v-col>
                <v-text-field
                  v-if="showPhone2"
                  label="Дополнительный телефон"
                  v-model="verification.phone_2"
                  v-mask="'8 (###) ###-##-##'"
                  :disabled="$store.getters.user.role === 'worker'"
                />

                <v-btn
                  v-else-if="showPhone1"
                  class="mt-3"
                  @click="showPhone2 = true"
                  outlined
                  :disabled="$store.getters.user.role === 'worker'"
                >
                  Добавить номер
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Адрес электронной почты абонента"
                  v-model="verification.client_email"
                  type="email"
                  :disabled="$store.getters.user.role === 'worker'"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Комментарий"
                  v-model="verification.comment"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          class="mb-6"
          :disabled="$store.getters.user.role === 'worker'"
        >
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  :items="meter_statuses.map(item => ({ value: item.id, text: item.name }))"
                  label="Статус"
                  v-model="verification.meter_status_id"
                  :disabled="$store.getters.user.role === 'worker'"
                />
              </v-col>

              <v-col>
                <v-text-field
                  label="Комментарий согласовывающего"
                  v-model="verification.coordinator_comment"
                  disabled
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  label="Дата поверки"
                  :value="verification.verification_date ? new Date(verification.verification_date).toLocaleString('ru-RU').substring(0, 10) : '-'"
                  disabled
                  v-if="$store.getters.user.role === 'worker'"
                />
                <Datepicker
                  title="Дата поверки"
                  :clearable="dateClearable()"
                  @clear="clearDateVerify"
                  v-model="verification.verification_date"
                  :is-new="!id"
                  @input="checkVerificationDate"
                  v-else
                />
              </v-col>

              <v-col>
                <v-autocomplete
                  :items="routes.filter((r) => r.date === verification.verification_date).map(item => ({ value: item.id, text: item.name }))"
                  label="Маршрут"
                  v-model="verification.route_id"
                  clearable
                  :disabled="$store.getters.user.role === 'worker'"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  :items="filteredVerifiers.map(item => ({ value: item.id, text: item.name }))"
                  label="Поверитель"
                  v-model="verification.verifier_id"
                  @click:clear="clearVerifierId"
                  :clearable="dateClearable()"
                  :disabled="$store.getters.user.role === 'worker'"
                />
              </v-col>

              <v-col>
                <v-select
                  :items="filteredMechanics.map(item => ({ value: item.id, text: item.name }))"
                  label="Слесарь"
                  v-model="verification.mechanic_id"
                  @click:clear="clearMechanicId"
                  :clearable="dateClearable()"
                  :disabled="$store.getters.user.role === 'worker'"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  :items="devices.map(item => ({ value: item.id, text: item.name }))"
                  label="Установка"
                  v-model="verification.device_id"
                  :disabled="$store.getters.user.role === 'worker'"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          class="mb-6"
          :disabled="$store.getters.user.role === 'worker'"
        >
          <v-card-text>
            <v-row>
              <v-col md="3">
                <v-text-field
                  label="Цена"
                  v-model="verification.price"
                  :disabled="!priceEditing || !verification.price_comment"
                />
              </v-col>

              <v-col>
                <v-select
                  v-model="verification.payment_method_id"
                  :items="payment_methods.map(item => ({ value: item.id, text: item.name }))"
                  :rules="[v => !!v || 'Выберите метод оплаты']"
                  :disabled="$store.getters.user.role === 'worker'"
                >
                  <template #label>
                    Метод оплаты<span class="error--text">*</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row v-if="verification.region_id === 2">
              <v-col md="3">
                <v-text-field
                  v-model="verification.ferry_count"
                  v-mask="'##########'"
                  :rules="[v => !!v || 'Введите количество паромов']"
                  @keyup="updatePrice"
                >
                  <template #label>
                    Количество паромов<span class="error--text">*</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  label="Причина изменения"
                  v-model="verification.price_comment"
                  :disabled="!priceEditing"
                  :persistent-hint="priceEditing"
                  hint="Обязательно при ручном изменении цены"
                >
                  <template #prepend>
                    <v-btn
                      icon
                      color="warning"
                      @click="priceEditing = !priceEditing"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          v-if="verification.type_id"
          class="mb-6"
          :disabled="$store.getters.user.role === 'worker'"
        >
          <v-card-text>
            <VerificationGasForm
              v-if="verification.job_type_id === 1 && verification.type_id === 1"
              v-model="verification.gas_meter"
              @meterUpdated="updatePrice"
            >
              <template #verification>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-if="fitStatusId !== 2"
                      label="Дата очередной поверки"
                      :clearable="dateClearable()"
                      v-model="verification.check_date"
                      type="date"
                      min="2000-01-01"
                      max="2100-12-31"
                      :disabled="$store.getters.user.role === 'worker'"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="number"
                      :label="computedFitStatusId ? 'Номер свидетельства о поверке' : 'Номер извещения о непригодности СИ'"
                      v-model="verification.number_of_certificate"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="verification.gas_meter.verification_method"
                      :items="verificationMethods"
                      label="Метод поверки"
                      :disabled="$store.getters.user.role === 'worker'"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="number"
                      :label="verification.job_type_id === 1 ? 'Показания счётчика после поверки' : 'Показания счётчика'"
                      v-model="verification.value_after_check"
                    />

                    <v-alert
                      v-if="wrongValueDifference > 7000"
                      class="mb-6"
                      outlined
                      color="error"
                      icon="warning"
                    >
                      Значение счётчика после поверки отличается от
                      изначального значения больше чем на {{ wrongValueDifference }}
                    </v-alert>

                    <v-alert
                      v-else-if="verification.value_after_check && wrongValueDifference < 0"
                      class="mb-6"
                      outlined
                      color="error"
                      icon="warning"
                    >
                      Значение счётчика после поверки меньше чем начальное значение!
                    </v-alert>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="verification.gas_meter.registration_number"
                      label="Регистрационный номер в Госреестре"
                      type="number"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Примечание"
                      v-model="verification.note"
                    />
                  </v-col>
                </v-row>
              </template>
            </VerificationGasForm>

            <VerificationWaterForm
              v-if="verification.job_type_id === 1 && verification.type_id === 2"
              v-model="verification.water_meter"
              @meterUpdated="updatePrice"
            >
              <template #verification>
                <v-row v-if="fitStatusId !== 2">
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Дата очередной поверки"
                      :clearable="dateClearable()"
                      v-model="verification.check_date"
                      type="date"
                      min="2000-01-01"
                      max="2100-12-31"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="number"
                      :label="computedFitStatusId ? 'Номер свидетельства о поверке' : 'Номер извещения о непригодности СИ'"
                      v-model="verification.number_of_certificate"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="verification.water_meter.verification_method"
                      :items="verificationMethods"
                      label="Метод поверки"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="number"
                      :label="verification.job_type_id === 1 ? 'Показания счётчика после поверки' : 'Показания счётчика'"
                      v-model="verification.value_after_check"
                    />

                    <v-alert
                      v-if="wrongValueDifference > 7000"
                      class="mb-6"
                      outlined
                      color="error"
                      icon="warning"
                    >
                      Значение счётчика после поверки отличается от
                      изначального значения больше чем на {{ wrongValueDifference }}
                    </v-alert>

                    <v-alert
                      v-else-if="verification.value_after_check && wrongValueDifference < 0"
                      class="mb-6"
                      outlined
                      color="error"
                      icon="warning"
                    >
                      Значение счётчика после поверки меньше чем начальное значение!
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Примечание"
                      v-model="verification.note"
                    />
                  </v-col>
                </v-row>
              </template>
            </VerificationWaterForm>

            <VerificationGasForm
              v-if="(verification.job_type_id === 2 || verification.job_type_id === 3) && verification.type_id === 1"
              title="Старый счетчик"
              mode="replace"
              v-model="verification.gas_meter"
              @meterUpdated="updatePrice"
            >
              <template #verification>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="verification.gas_meter.verification_method"
                      :items="verificationMethods"
                      label="Метод поверки"
                    />
                  </v-col>
                </v-row>
              </template>
            </VerificationGasForm>

            <VerificationGasForm
              v-if="(verification.job_type_id === 2 || verification.job_type_id === 3) && verification.type_id === 1"
              title="Новый счетчик"
              mode="replace"
              v-model="verification.new_gas_meter"
              @meterUpdated="updatePrice"
            >
              <template #verification>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-if="fitStatusId !== 2"
                      label="Дата очередной поверки"
                      :clearable="dateClearable()"
                      v-model="verification.check_date"
                      type="date"
                      min="2000-01-01"
                      max="2100-12-31"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="number"
                      :label="computedFitStatusId ? 'Номер свидетельства о поверке' : 'Номер извещения о непригодности СИ'"
                      v-model="verification.number_of_certificate"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="verification.new_gas_meter.verification_method"
                      :items="verificationMethods"
                      label="Метод поверки"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="number"
                      :label="verification.job_type_id === 1 ? 'Показания счётчика после поверки' : 'Показания счётчика'"
                      v-model="verification.value_after_check"
                    />

                    <v-alert
                      v-if="wrongValueDifference > 7000"
                      class="mb-6"
                      outlined
                      color="error"
                      icon="warning"
                    >
                      Значение счётчика после поверки отличается от
                      изначального значения больше чем на {{ wrongValueDifference }}
                    </v-alert>

                    <v-alert
                      v-else-if="verification.value_after_check && wrongValueDifference < 0"
                      class="mb-6"
                      outlined
                      color="error"
                      icon="warning"
                    >
                      Значение счётчика после поверки меньше чем начальное значение!
                    </v-alert>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="verification.new_gas_meter.registration_number"
                      label="Регистрационный номер в Госреестре"
                      type="number"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Примечание"
                      v-model="verification.note"
                    />
                  </v-col>
                </v-row>
              </template>
            </VerificationGasForm>

            <VerificationWaterForm
              v-if="(verification.job_type_id === 2 || verification.job_type_id === 3) && verification.type_id === 2"
              title="Старый счетчик"
              v-model="verification.water_meter"
              @meterUpdated="updatePrice"
            />

            <VerificationWaterForm
              v-if="(verification.job_type_id === 2 || verification.job_type_id === 3) && verification.type_id === 2"
              title="Новый счетчик"
              v-model="verification.new_water_meter"
              @meterUpdated="updatePrice"
            >
              <template #verification>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-if="fitStatusId !== 2"
                      label="Дата очередной поверки"
                      :clearable="dateClearable()"
                      v-model="verification.check_date"
                      type="date"
                      min="2000-01-01"
                      max="2100-12-31"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="number"
                      :label="computedFitStatusId ? 'Номер свидетельства о поверке' : 'Номер извещения о непригодности СИ'"
                      v-model="verification.number_of_certificate"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="verification.new_water_meter.verification_method"
                      :items="verificationMethods"
                      label="Метод поверки"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="number"
                      :label="verification.job_type_id === 1 ? 'Показания счётчика после поверки' : 'Показания счётчика'"
                      v-model="verification.value_after_check"
                    />

                    <v-alert
                      v-if="wrongValueDifference > 7000"
                      class="mb-6"
                      outlined
                      color="error"
                      icon="warning"
                    >
                      Значение счётчика после поверки отличается от
                      изначального значения больше чем на {{ wrongValueDifference }}
                    </v-alert>

                    <v-alert
                      v-else-if="verification.value_after_check && wrongValueDifference < 0"
                      class="mb-6"
                      outlined
                      color="error"
                      icon="warning"
                    >
                      Значение счётчика после поверки меньше чем начальное значение!
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Примечание"
                      v-model="verification.note"
                    />
                  </v-col>
                </v-row>
              </template>
            </VerificationWaterForm>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card
      v-if="verification.images && verification.images.length > 0"
      class="mb-6"
    >
      <v-card-text>
        <div class="d-flex images">
          <v-img
            v-for="image in verification.images"
            :key="image.id"
            :src="getImageFullPath(image.path)"
            class="images__item"
            @click="displayImage(image.path)"
          >
            <div class="text-right">
              <v-btn
                icon
                color="error"
                @click.stop="removeImage(image.id)"
                :disabled="$store.getters.user.role === 'worker'"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-img>
        </div>
      </v-card-text>
    </v-card>

    <v-card
      :disabled="$store.getters.user.role === 'worker'"
    >
      <v-card-text>
        <v-file-input
          v-model="images"
          color="deep-purple accent-4"
          counter
          accept="image/png, image/jpeg, image/gif"
          label="Изображения"
          multiple
          placeholder="Выберите изображения"
          prepend-icon="mdi-paperclip"
          outlined
          :show-size="1000"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
              color="deep-purple accent-4"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
      </v-card-text>
    </v-card>

    <v-overlay
      class="overlay"
      :value="popupImage !== null"
    >
      <v-img
        class="overlay__image"
        :src="popupImage"
        contain
      />

      <div class="overlay__button-wrapper">
        <v-btn
          class="overlay__button"
          @click="popupImage = null"
        >
          Закрыть
        </v-btn>
      </div>
    </v-overlay>

    <v-btn
      outlined
      color="blue"
      type="submit"
      class="mb-8 mt-10"
      :loading="verificationSaving"
      :disabled="isSaveDisabled"
    >
      Сохранить
    </v-btn>

    <v-dialog
      v-model="showIncorrectDateWarning"
      width="500"
    >
      <v-card>
        <v-card-title>
          Проверьте дату
        </v-card-title>

        <v-card-text>
          Дата поверки уже прошла, вы уверены что хотите указать эту дату?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            @click="verification.verification_date = null; showIncorrectDateWarning = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="primary"
            @click="showIncorrectDateWarning = false"
          >
            Подтвердить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'
Vue.use(VueMask, {
  placeholders: {
    'm': /[0-1]/,
    'd': /[0-3]/
  }
})

import cloneDeep from 'lodash/cloneDeep'
import Dadata from '@/components/Dadata'

import { HOST } from "@/config"

import Datepicker from '@/components/Datepicker'
import { ListsService, DevicesService, VerificationsService, PricesService } from '@/common/api.service'

import VerificationGasForm from '@/components/Verifications/Partials/Gas'
import VerificationWaterForm from '@/components/Verifications/Partials/Water'

export default {
  components: {
    Dadata,
    Datepicker,
    VerificationGasForm,
    VerificationWaterForm
  },

  props: {
    id: {
      default: false
    }
  },

  data() {
    return {
      // Константа, ID цены парома
      ferryPriceId: 1000,
      tmp: null,
      verificationSaving: false,
      emptyState: {
        verification_date: '', // new Date().toISOString().substr(0, 10),
        region_id: null,
        district_id: null,
        phone: null,
        phone_1: null,
        phone_2: null,
        client_email: null,
        name: null,
        verifier_id: null,
        mechanic_id: null,
        type_id: 1,
        client_status_id: 1,
        device_id: null,
        job_type_id: 1,
        check_date: null,
        number_of_certificate: null,
        value_after_check: null,
        note: null,
        meter_status_id: 1,
        comment: '',
        coordinator_comment: '',
        gas_meter: {},
        water_meter: {},
        new_gas_meter: {},
        new_water_meter: {},

        address: null,
        city: '',
        street: '',
        house: '',
        corps: '',
        flat: '',
        lat: '',
        lng: '',
        price: null,
        price_comment: null,
        payment_method_id: null,
        registration_number: null,
        ferry_count: null
      },

      verification: {},

      images: [],
      deleteImages: [],

      // Показывать ли дополнительные поля для номеров телефона
      showPhone1: false,
      showPhone2: false,

      regions: [],
      districts: [],
      verifiers: [],
      mechanics: [],
      prices: [],
      types: [],
      client_statuses: [],
      devices: [],
      job_types: [],
      meter_statuses: [],
      routes: [],
      payment_methods: [],

      popupImage: null,
      host: HOST,
      gasCounterValue: null,
      fitStatusId: 1,

      valid: true,
      rules: {
        // Валидация формата даты
        date: [
          v => (!v || /^\d{4}-\d{2}-\d{2}$/.test(v)) || 'Некорректный формат даты. Пример 2021-01-31',
        ]
      },

      // Редактирование цены
      priceEditing: false,

      // Предупреждение о некорректной дате поверки
      showIncorrectDateWarning: false,

      // Методы поверки
      verificationMethods: [
        'ГСИ. Счетчики газа ГС4 (GS4). Методика поверки ГС4-010.00 МП',
        '"Методика поверки" в составе эксплуатационной документации СЯМИ 407 274-103 РЭ',
        '4213-001-037996313-16 МП',
        '4213-002-037996313-2014 МП',
        '4213-004-70670506-2010 МП',
        '562.М.Т2.784.000Д1',
        'выпуск до 03.04.2022 ПДЕК.407292.009 И1 с изменением №1 выпуск до 03.04.2022 ПДЕК.407292.009 И1 с изменением №2',
        'выпуск до 05.12.2022 ГОСТ 8.324-2002 выпуск с 06.12.2022 ЭРГП.407269.000И1',
        'выпуск с 01.05.2001 по 30.06.2003: И-409-94 выпуск с 01.07.2003: "Методика поверки" в составе эксплуатационной документации СЯМИ 407 274-144 РЭ',
        'ГОСТ 8.324-2002',
        'ГОСТ 8.324-2002 или ЭРГП.407269.000И1',
        'ГОСТ 8.324-2002 поверка на расходах: Qmin; 0,1Qnom; Qnom; Qmax',
        'ГЮНК 407260.011 МП',
        'ГЮНК. 407260.005МП',
        'ГЮНК.407260.004 МП',
        'Документ по поверке в составе эксплуатационной документации РГДИ.407269.001. РЭ',
        'Документ по поверке в составе эксплуатационной документации СЯМИ.407274-144 РЭ',
        'Инструкция. ГСИ. Счетчики бытовые СГБ-G4. Методика поверки',
        'Инструкция.ГСИ. Счетчики газа бытовые модели NP фирмы "Нуово Пиньона" (Италия). Методика поверки',
        'Инструкция.ГСИ. Счетчики газа мембранные',
        'Инструкция.ГСИ. Счетчики газа типа NP. Методика поверки',
        'Инструкция.Счетчики газа бытовые СГБ G2,5; СГБ G4-1;СГБ G4; СГБ G6. Методика поверки. И-409-94',
        'Инструкция.Счетчики газа бытовые СГБ G2,5; СГБ G4-1. Методика поверки',
        'ИЮАШ.407269.000 И1',
        'ИЮАШ.407269.000 МП',
        'Методика поверки',
        'МП 00567-13-2017',
        'МП 0725-1-2018',
        'МП 0911/2-311229-2018',
        'МП 140-30151-2015',
        'МП 208-017-2016',
        'МП 2550-0054-2009',
        'МП 2550-0054-2010',
        'МП 30051-21370-2015',
        'МП 47860-11',
        'МП 51758-12',
        'МП 61928-15',
        'МП РТ-475-98',
        'МП-2550-0312-2018',
        'МП-2550-0350-2019',
        'МРБ МП.1778-2008 с извещением об изменении №2',
        'МРБ МП.1778-2008 с извещением об изменении №5',
        'МТ РТ 2102-2014',
        'МЦКЛ.0046.МП',
        'нет данных',
        'ОЦСМ 0496196-2018 МП',
        'ОЦСМ 080196-2019 МП',
        'ПДЕК.407292.00 И1',
        'ПМТК.407273.001 МП',
        'Приложение А "Методика поверки" в составе руководства по эксплуатации СЯМИ 407 274-287 РЭ',
        'Приложение А "Методика поверки" руководства по эксплуатации СЯМИ 407 274-287 РЭ',
        'Приложение Б "Методика поверки" руководства по эксплуатации СЯМИ 407 274-287 РЭ',
        'раздел 3.4 "Методика поверки" Руководства по эксплуатации РГДИ.407269.001. РЭ',
        'РГДИ.407269.001 МП',
        'РД 50-211-80',
        'СКШД.407369.002 МП',
        'СПЭФ.407269.006 МП',
        'СПЭФ.407279.002 МП',
        'СПЭФ.407279.003 МП',
        'СПЭФ.407279.005 МП с изменением №1',
        'Счетчики газа бытовые. Методика поверки. МЦ КЛ.0009.МП',
        'ТАСВ.407269.001 МП',
        'ЫШ 2.833.021 МП',
        'ЫШ 2.833.021-01 МП',
        'Ю72.833.00Д1 "Счетчики газа G6. Методика поверки"',
        'ЯШИУ.407279.001 И2',
        'ЯШИУ.407279.001-12.1 И2',
      ]
    }
  },

  watch: {
    verification: {
      deep: true,
      handler(value) {
        if (value.value_after_check) {
          // this.wrongValueDifference = this.verification.value_after_check - this.gasCounterValue
        }

        if (!this.verification.new_gas_meter) {
          this.verification.new_gas_meter = {}
        }

        if (!this.verification.new_water_meter) {
          this.verification.new_water_meter = {}
        }
      }
    },
    /*
    computedPrice: {
      handler (value) {
        if (value && !this.verification.id) {
          this.verification.price = value
        } else if (!this.verification.id) {
          this.verification.price = null
        }
      }
    }
    */
  },

  computed: {
    //
    computedFitStatusId () {
      return this.fitStatusId === 1
    },

    //
    wrongValueDifference () {
      let meterValue = 0;

      // Газ
      if (this.verification.type_id === 1) {
        // Поверка
        if (this.verification.job_type_id === 1 && this.verification.gas_meter) {
          meterValue = parseInt(this.verification.gas_meter.value)
        } else if (this.verification.new_gas_meter) {
          meterValue = parseInt(this.verification.new_gas_meter.value)
        }
      }

      // Вода
      else if (this.verification.type_id === 2) {
        // Поверка
        if (this.verification.job_type_id === 1 && this.verification.water_meter) {
          meterValue = parseInt(this.verification.water_meter.value)
        } else if (this.verification.new_water_meter) {
          meterValue = parseInt(this.verification.new_water_meter.value)
        }
      }

      return parseInt(this.verification.value_after_check) - meterValue
    },

    // Районы, отфильтрованные по выбранному региону
    filteredDistricts () {
      if (!this.verification.region_id) {
        return []
      }

      return this.districts.filter((district) => this.verification.region_id === district.region_id)
    },

    //
    filteredMechanics () {
      if (!this.verification.region_id) {
        return []
      }

      return this.mechanics.filter((mechanic) => this.verification.region_id === mechanic.region_id)
    },

    //
    filteredVerifiers () {
      if (!this.verification.region_id) {
        return []
      }

      return this.verifiers.filter((verifier) => this.verification.region_id === verifier.region_id)
    },

    // Цена, посчитанная с учётом региона и типа работ
    /*
    computedPrice () {
      const ferryPrice = this.prices.find(price => price.id === this.ferryPriceId)

      const fPrice = ferryPrice && this.verification.ferry_count ? parseFloat(ferryPrice.price) * parseInt(this.verification.ferry_count) : 0

      const verificationPrice = this.prices.find(price => {
        return price.region_id === this.verification.region_id
          && price.type_id === this.verification.type_id
          && price.district_id === this.verification.district_id
          && price.job_type_id === this.verification.job_type_id
          && ((this.verification.new_water_meter && price.meter_id === this.verification.new_water_meter.meter_id) ||
            (this.verification.water_meter && price.meter_id === this.verification.water_meter.meter_id) ||
            (this.verification.new_gas_meter && price.meter_id === this.verification.new_gas_meter.meter_id) ||
            (this.verification.gas_meter && price.meter_id === this.verification.gas_meter.meter_id)
          )
      })

      const vPrice = verificationPrice ? parseFloat(verificationPrice.price) : 0

      return vPrice ? vPrice + fPrice : fPrice
    },
    */

    // Запрещено сохранение
    isSaveDisabled () {
      if (this.verification.value_after_check && this.wrongValueDifference < 0) {
        return true
      }

      return this.verificationSaving
    }
  },

  methods: {
    //
    updatePrice () {
      const ferryPrice = this.prices.find(price => price.id === this.ferryPriceId)

      const fPrice = ferryPrice && this.verification.ferry_count ? parseFloat(ferryPrice.price) * parseInt(this.verification.ferry_count) : 0

      const verificationPrice = this.prices.find(price => {
        return price.region_id === this.verification.region_id
          && price.type_id === this.verification.type_id
          && price.district_id === this.verification.district_id
          && price.job_type_id === this.verification.job_type_id
          && ((this.verification.new_water_meter && price.meter_id === this.verification.new_water_meter.meter_id) ||
            (this.verification.water_meter && price.meter_id === this.verification.water_meter.meter_id) ||
            (this.verification.new_gas_meter && price.meter_id === this.verification.new_gas_meter.meter_id) ||
            (this.verification.gas_meter && price.meter_id === this.verification.gas_meter.meter_id)
          )
      })

      const vPrice = verificationPrice ? parseFloat(verificationPrice.price) : 0

      const result = vPrice ? vPrice + fPrice : fPrice

      if (result) {
        this.verification.price = result
      } else {
        this.verification.price = null
      }
    },

    //
    addressSelected (suggestion) {
      if (!suggestion) {
        this.verification.address = null
        this.verification.city = ''
        this.verification.street = ''
        this.verification.house = ''
        this.verification.corps = ''
        this.verification.flat = ''
        this.verification.lat = ''
        this.verification.lng = ''
        return
      }

      if (suggestion.data.area_with_type) {
        const district = this.districts.find((dist) => {
          return dist.region_id === this.verification.region_id &&
            (
              dist.name === suggestion.data.area_with_type ||
              dist.name === suggestion.data.area
            )
        })

        if (district) {
          this.verification.district_id = district.id
        } else {
          this.verification.district_id = null
        }
      } else if (suggestion.data.city_district_with_type) {
        const district = this.districts.find((dist) => {
          return dist.region_id === this.verification.region_id &&
            (
              dist.name === suggestion.data.city_district_with_type ||
              dist.name === suggestion.data.city_district
            )
        })

        if (district) {
          this.verification.district_id = district.id
        } else {
          this.verification.district_id = null
        }
      } else {
        this.verification.district_id = null
      }

      if (suggestion.data.city) {
        this.verification.city = suggestion.data.city
      } else if (suggestion.data.settlement) {
        this.verification.city = suggestion.data.settlement
      }

      this.verification.street = suggestion.data.street
      this.verification.house = suggestion.data.house
      this.verification.corps = suggestion.data.block
      this.verification.flat = suggestion.data.flat
      this.verification.lat = suggestion.data.geo_lat
      this.verification.lng = suggestion.data.geo_lon
    },

    //
    onSubmit () {
      if (!this.$refs.verificationForm.validate()) {
        this.$vuetify.goTo('.error--text')
        return
      }

      this.verificationSaving = true

      // Update
      if (this.id) {
        VerificationsService
          .update(this.id, this.verification)
          .then(() => {
            const date = this.verification.verification_date ? this.verification.verification_date : 'no-date'
            this.resetState()
            const redirect = this.$store.getters.user.role === 'filial_manager' || this.$store.getters.user.role === 'director' ? `${this.$store.getters.user.role}/verifications` : (this.$store.getters.user.worker_type_id === 2 ? 'mechanic' : this.$store.getters.user.role)
            this.$router.push({ path: `/${redirect}`, query: { date: date } })
            this.$notification('Успешно сохранено')
          })
          .catch(() => {
            this.$notification('Ошибка сохранения')
          })
          .finally(() => {
            this.verificationSaving = false
          })

        const formData = new FormData();
        this.images.forEach(item => {
          formData.append('images[]', item)
        })
        this.deleteImages.forEach(item => {
          formData.append('deleteImages[]', item)
        })
        VerificationsService.updateImages(this.id, formData)
      } else {
        // Add
        VerificationsService
          .add(this.verification)
          .then(async (data) => {
            const formData = new FormData();
            this.images.forEach(item => formData.append('images[]', item))
            this.deleteImages.forEach(item => {
              formData.append('deleteImages[]', item)
            })
            await VerificationsService.updateImages(data.data.verification.id, formData)
            const date = this.verification.verification_date ? this.verification.verification_date : 'no-date'
            this.resetState()
            const redirect = this.$store.getters.user.role === 'filial_manager' || this.$store.getters.user.role === 'director' ? `${this.$store.getters.user.role}/verifications` : this.$store.getters.user.role
            this.$router.push({ path: `/${redirect}`, query: { date: date } })
            this.$notification('Успешно сохранено')
          })
          .catch(() => {
            this.$notification('Ошибка сохранения')
          })
          .finally(() => {
            this.verificationSaving = false
          })
      }
    },

    //
    resetState () {
      this.verification = cloneDeep(this.emptyState)
      this.images = []
    },

    //
    displayImage (path) {
      this.popupImage = this.getImageFullPath(path)
    },

    //
    getImageFullPath (path) {
      return `${this.host}/storage/images/${path}`
    },

    //
    dateClearable () {
      return this.verification.meter_status_id !== 3
    },

    //
    clearVerifierId () {
      this.verification.verifier = null
      this.verification.verifier_id = null
    },

    //
    clearMechanicId () {
      this.verification.mechanic = null
      this.verification.mechanic_id = null
    },

    //
    clearDateVerify () {
      this.verification.verification_date = null
    },

    // Удаляет изображение
    removeImage (id) {
      // Скрываем от пользователя
      this.verification.images = this.verification.images.filter(image => image.id !== id)

      // Помещаем в массив на удаление при сохранении
      this.deleteImages.push(id)
    },

    // Проверяет дату поверки
    checkVerificationDate (value) {
      const currentDate = new Date().toISOString().split('T')[0]

      if (value < currentDate) {
        this.showIncorrectDateWarning = true
      }
    },

    //
    regionSelected () {
      this.verification.district_id = null
      this.addressSelected()

      this.updatePrice()
    },

    //
    districtSelected () {
      if (this.verification.district_id) {
        const district = this.districts.find((dist) => dist.id === this.verification.district_id)

        if (district && district.name === 'Рязань') {
          // Статус ожидание
          this.verification.meter_status_id = 1
        }
      }

      this.updatePrice()
    }
  },

  beforeMount () {
    this.resetState()

    ListsService.get(['types', 'client_statuses', 'job_types', 'regions', 'districts', 'meter_statuses', 'routes', 'payment_methods', 'verifiers', 'mechanics']).then(({data}) => {
      this.types = data.types
      this.client_statuses = data.client_statuses
      this.job_types = data.job_types
      this.regions = data.regions
      this.districts = data.districts
      this.meter_statuses = data.meter_statuses
      this.routes = data.routes
      this.payment_methods = data.payment_methods
      this.verifiers = data.verifiers
      this.mechanics = data.mechanics
    })

    DevicesService.get().then(({data}) => {
      this.devices = data.devices
    })

    PricesService.get().then(({data}) => {
      this.prices = data
    })

    if (this.id) {
      VerificationsService
        .get(this.id)
        .then(({data}) => {
          this.verification = cloneDeep(data.verification)

          if (this.verification.phone_1) {
            this.showPhone1 = true
          }

          if (this.verification.phone_2) {
            this.showPhone2 = true
          }
        })
    }
  },

  beforeDestroy () {
    if (
      this.verification?.id && (
      (
        this.verification.gas_meter
        && (
          !this.verification.gas_meter.brand
          || !this.verification.gas_meter.meter?.id
        )
      ) || (
        this.verification.water_meter
        && (
          !this.verification.water_meter.type
          || !this.verification.water_meter.meter?.id
        )
      ))
    ) {
      this.$notification("Не заполнены поля «Марка» или «Типоразмер счетчика».")
    }
  },
}
</script>

<style lang="scss">
.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;

  &__item {
    box-sizing: border-box;
    max-width: calc(20% - 16px);
    margin-right: 16px;
    margin-bottom: 16px;

    @media (max-width: 600px) {
      max-width: calc(50% - 16px);
    }
  }
}

.overlay {
  &__image {
    max-width: 80vw;
    max-height: 80vh;
    margin-bottom: 16px;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
  }

  &__button {
    margin: 0 auto;
  }
}
</style>
