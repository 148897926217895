<template>
  <v-row>
    <v-col cols="12" align="right" class="mt-4">
      <v-btn
        text
        @click="verificationExport"
      >
        Скачать в xlsx
      </v-btn>

      <v-btn
        text
        color="primary"
        @click="verificationExportShort"
      >
        Согласование в xlsx
      </v-btn>

      <v-btn
        text
        @click="verificationDownload"
      >
        Скачать акты и фото
      </v-btn>
    </v-col>

    <v-container fluid class="filter">
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <v-autocomplete
            :items="regions.map(item => ({ value: item.id, text: item.name }))"
            label="Регион"
            v-model="query.region_id"
            multiple
            clearable
            disabled
          />
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <v-autocomplete
            :items="filteredDistricts.map(item => ({ value: item.id, text: item.name }))"
            label="Район"
            v-model="query.district_id"
            multiple
            clearable
          />
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >

          <Datepicker
            title="Дата"
            v-model="query.verification_date"
            clearable
          />
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <v-select
            :items="meter_statuses.map(item => ({ value: item.id, text: item.name }))"
            label="Статус"
            v-model="query.meter_status_id"
            clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <v-text-field
            label="Телефон, адрес, или фамилия"
            v-model="query.search"
            clearable
          />
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <v-select
            :items="types.map(item => ({ value: item.id, text: item.name }))"
            label="Тип"
            v-model="query.type_id"
            clearable
          />
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <v-select
            :items="workers.map(item => ({ value: item.id, text: item.name }))"
            label="Поверитель"
            v-model="query.verifier_id"
            clearable
          />
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <v-autocomplete
            :items="routes.map(item => ({ value: item.id, text: item.name }))"
            label="Маршрут"
            v-model="query.routes"
            multiple
            clearable
          />
        </v-col>
      </v-row>

      <v-col
        cols="12"
        md="2"
        sm="3"
      >
        <v-select
          :items="authors.map(item => ({ value: item.id, text: item.name }))"
          label="Автор"
          v-model="query.manager_id"
          clearable
        />
      </v-col>

      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          sm="3"
          offset-sm="9"
        >
          <v-btn width="50%" text @click="clearSearchQuery">
            Очистить
          </v-btn>

          <v-btn width="50%" @click="find()">
            Найти
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-col cols="12">
      <verifications-list
        :verifications="verifications"
        v-on:delete="remove"
        @selectChanged="setSelected"
        :options.sync="query"
        v-on:update="queryUpdated"
        :total="total"
        :routes="routes"
        v-on:updated="queryUpdated"
      />

      <delete-confirmation
        :visible="deleteConfirmationVisible"
        v-on:confirm="removeConfirmed"
        v-on:decline="removeDeclined"
      />
    </v-col>
  </v-row>
</template>

<script>
import VerificationsList from "@/components/Verifications/List";
import { ListsService, UsersService, VerificationsService, RoutesService } from "@/common/api.service"
import Datepicker from '@/components/Datepicker'
import {HOST} from "@/config"
import DeleteConfirmation from "@/components/Modals/DeleteConfirmation";

export default {
  components: {
    VerificationsList,
    Datepicker,
    DeleteConfirmation
  },

  data() {
    return {
      overlay: false,
      verifications: null,
      isLoaded: false,
      query: null,
      regions: [],
      types: [],
      districts: [],
      meter_statuses: [],
      workers: [],
      authors: [],
      routes: [],
      host: HOST,
      deleteConfirmationVisible: false,
      deleteVerificationId: null,

      selected: [],
      total: 0
    }
  },

  computed: {
    selectedRequest () {
      const formData = new FormData()

      for (const [key] of Object.entries(this.query)) {
        if (this.query[key] !== null && key !== 'page' && key !== 'itemsPerPage' && key !== 'sortBy' && key !== 'sortDesc') {
          formData.append(key, this.query[key])
        }
      }

      formData.append('verifications', this.selected.join(','))

      return formData
    },

    // Районы, отфильтрованные по выбранному региону
    filteredDistricts () {
      if (!this.query.region_id || this.query.region_id.length === 0) {
        return this.districts
      }

      return this.districts.filter((district) => this.query.region_id.includes(district.region_id))
    }
  },

  methods: {verificationExport () {
      fetch(`${this.host}/verification/export`, {
        body: this.selectedRequest,
        method: 'POST',
      })
        .then(resp => {
          return resp.blob()
        })
        .then(blob => {
          const date = new Date()

          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = `verifications_${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}_${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.xlsx`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((e) => alert('oh no!' + e))
    },

    verificationExportShort () {
      fetch(`${this.host}/verification/export_short`, {
        body: this.selectedRequest,
        method: 'POST',
      })
        .then(resp => {
          return resp.blob()
        })
        .then(blob => {
          const date = new Date()

          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = `verifications_${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}_${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.xlsx`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((e) => alert('oh no!' + e))
    },

    verificationDownload () {
      fetch(`${this.host}/verification/download`, {
        body: this.selectedRequest,
        method: 'POST',
      })
        .then(resp => {
          return resp.blob()
        })
        .then(blob => {
          const date = new Date()

          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = `verifications_${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}_${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.zip`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((e) => alert('oh no!' + e))
    },

    setSelected (value) {
      this.selected = value
    },

    find (page) {
      if (!page) {
        this.query.page = 1
      }

      VerificationsService
        .get(false, this.query)
        .then((response) => {
          this.verifications = response.data
          this.total = response.meta.total
        })
    },

    clearSearchQuery() {
      for(const [key] of Object.entries(this.query)) {
        if (!['sortBy', 'sortDesc', 'itemsPerPage', 'page'].includes(key)) {
          this.query[key] = null
        }
      }

      this.selected = []

      this.find()
    },

    queryUpdated (val) {
      this.query.sortBy[0] = val.sortBy[0]
      this.query.sortDesc[0] = val.sortDesc[0]
      this.query.itemsPerPage = val.itemsPerPage
      this.query.page = val.page

      this.find(val.page)
    },

    // Нажата кнопка 'удалить'
    // Сохраняем ID и показываем предупреждение
    remove(id) {
      this.deleteVerificationId = id
      this.deleteConfirmationVisible = true
    },

    // Юзер подтвердил удаление
    removeConfirmed() {
      this.deleteConfirmationVisible = false

      VerificationsService.delete(this.deleteVerificationId).then(() => {
        VerificationsService
          .get(false, this.query)
          .then((response) => {
            this.verifications = response.data
            this.total = response.meta.total
          })
      })
    },

    // Юзер передумал удалять
    removeDeclined() {
      this.deleteConfirmationVisible = false
    }
  },

  beforeMount() {
    this.query = this.$store.state.common.verificationQuery
    this.query.region_id = [this.$store.state.auth.user.region_id]

    ListsService
      .get(['regions', 'types', 'districts', 'meter_statuses', 'authors'])
      .then(({data}) => {
        this.regions = data.regions
        this.types = data.types
        this.districts = data.districts
        this.meter_statuses = data.meter_statuses
        this.authors = data.authors
      })

    UsersService
      .workers(1)
      .then(({data}) => {
        this.workers = data.users
      })

    VerificationsService
      .get(false, this.query)
      .then((response) => {
        this.verifications = response.data
        this.total = response.meta.total
      })

    RoutesService
      .get(false)
      .then((result) => {
        this.routes = result.routes
      })
  },

  watch: {
    // При изменении параметров сохраняем их в стор, чтобы при возврате на страницу использовать те же фильтры
    query: {
      handler () {
        this.$store.commit('SET_VERIFICATION_QUERY', this.query)
      },

      deep: true
    }
  },
}
</script>
